<template>
  <div class="model">
    <!-- 溯源 -->
    <div class="default-box" v-show="isBoxShowNum === 0">
      <img src="../assets/image/traceability/icon1.png" />
      <p class="msg">请在<span>左侧</span>选择</p>
      <p class="msg"><span>指定批次</span>进行<span>操作</span></p>
    </div>
    <div class="default-box" v-show="isBoxShowNum === 12">
      <img src="../assets/image/traceability/icon1.png" />
      <p class="msg">请在<span>左侧</span>选择</p>
      <p class="msg"><span>指定农场秀</span>进行<span>操作</span></p>
    </div>
    <!-- 编辑 -->
    <div class="edit-box" v-show="isBoxShowNum === 1">
      <p class="title">编辑批次</p>
      <div class="edit-main">
        <div class="center-card">
          <div class="input-suffix">
            批次名称
            <el-input
              placeholder="请输入批次名称"
              v-model.trim="batchInfo.title"
              maxlength="20"
            ></el-input>
          </div>
          <div class="input-suffix">
            种植地块
            <el-select v-model="selectVal" placeholder="请选择种植地块">
              <el-option
                :label="item.title"
                :value="item.id"
                v-for="item in landList"
                :key="item.id"
              ></el-option>
            </el-select>
          </div>
          <div class="input-suffix">
            开始时间
            <el-date-picker
              class="edit-date-picker"
              v-model="startDateValue"
              type="datetime"
              placeholder="请选择开始时间"
              :clearable="false"
              prefix-icon="el-icon-arrow-right"
            >
            </el-date-picker>
          </div>
          <div class="input-suffix">
            结束时间
            <el-date-picker
              class="edit-date-picker"
              v-model="endDateValue"
              type="datetime"
              placeholder="请选择开始时间"
              :clearable="false"
              prefix-icon="el-icon-arrow-right"
            >
            </el-date-picker>
          </div>
          <el-button type="primary" @click="handleOkEdit">确定</el-button>
          <el-button type="info" @click="handleNoEdit">取消</el-button>
        </div>
      </div>
    </div>
    <!-- 预览-->
    <div class="preview-box" v-if="isBoxShowNum === 2">
      <iframe
        v-if="sn"
        class="pre-bg-img"
        id="pre-bg-img"
        :src="
          'https://data.darsing.net/trace/track.html?SN=' +
          sn +
          '&device_id=' +
          device_id +
          '&position=' +
          position +
          '&location=' +
          location
        "
      ></iframe>
    </div>
    <!-- 查看-->
    <div class="see-box" v-show="isBoxShowNum === 3">
      <div class="qr-code-card">
        <div class="qr-title">数字农业</div>
        <div class="qr-main">
          <vue-qr
            v-if="sn"
            class="qr-img"
            :text="
              'https://data.darsing.net/trace/track.html?SN=' +
              sn +
              '&device_id=' +
              device_id +
              '&position=' +
              position +
              '&location=' +
              location
            "
            :size="size"
          />
          <p>手机扫描二维码<br />即可查看</p>
        </div>
      </div>
      <div class="preview-button" @click="handlePreview">预览</div>
    </div>
    <!-- 删除-->
    <div class="del-box" v-show="isBoxShowNum === 4">
      <div class="del-message">
        <div class="del-content">确认删除批次{{ sn }}</div>
        <div class="bottom-btn">
          <div class="cancel" @click="noDel">取消</div>
          <div class="determine" @click="delSn(id)">确定</div>
        </div>
      </div>
    </div>

    <!-- 开始/结束 时间 -->
    <div class="set-time-box" v-show="isBoxShowNum === 5 || isBoxShowNum === 6">
      <p class="set-title">{{ timePageTitle }}</p>
      <div class="change-time" @click="openCalendar">
        <el-date-picker
          class="my-date-picker"
          v-model="dateValue"
          type="datetime"
          :placeholder="timeTipsTxt"
          :clearable="false"
        >
        </el-date-picker>
        <img class="icon3" src="../assets/image/traceability/icon3.png" />
      </div>
      <el-button type="primary" @click="handleYesEditTime(isBoxShowNum)"
        >是</el-button
      >
      <el-button type="info" @click="handleNoEditTime(isBoxShowNum)"
        >否</el-button
      >
      <img class="icon5" src="../assets/image/traceability/icon5.png" />
    </div>
    <!-- 信息编辑 -->
    <div class="info-box" v-if="isBoxShowNum === 7">
      <img
        class="info-bg-img"
        :src="
          productInfo.photo
            ? uploadImgs + productInfo.photo
            : require('../assets/image/traceability/infobgimg.png')
        "
      />
      <div class="info-card">
        <div class="info-title">{{ productInfo.title }}</div>
        <div class="info-col">
          <div class="info-col-title">品种分类</div>
          <div class="info-col-content">
            {{ productInfo.cname ? productInfo.cname : "无" }}
          </div>
        </div>
        <div class="info-col">
          <div class="info-col-title">产品批次</div>
          <div class="info-col-content">
            {{ batch_info.title ? batch_info.title : "无" }}
          </div>
        </div>
        <div class="info-col">
          <div class="info-col-title">批次周期</div>
          <div class="info-col-content">
            {{ batch_info.start_time ? batch_info.start_time : "" }}—{{
              batch_info.end_time ? batch_info.end_time : ""
            }}
          </div>
        </div>
        <div class="info-col content-col">
          <div class="info-col-title">产品简介</div>
          <div class="info-col-content">
            {{ productInfo.desc ? productInfo.desc : "无" }}
          </div>
        </div>
      </div>
    </div>
    <!-- 溯源结束 -->

    <!-- 农场秀开始 -->
    <!-- 扫码分享 -->
    <div class="farm-share" v-show="isBoxShowNum === 8">
      <div class="qr-code-card">
        <div class="qr-title">大旭数字农业农场秀</div>
        <div class="qr-main">
          <vue-qr
            v-if="templetId && showId"
            class="qr-img"
            :text="
              'https://data.darsing.net/show/show.html?manor_show_id=' +
              showId +
              '&templetId=' +
              templetId +
              '&farmId=' +
              farmID +
              '&token=' +
              token
            "
            :size="size"
          />
          <p>使用手机扫一扫</p>
          <!-- <p>点击右上角分享或加入收藏</p> -->
        </div>
      </div>
    </div>
    <!-- 删除-->
    <div class="farm-del" v-show="isBoxShowNum === 9">
      <div class="layer">
        <div class="del-message">
          <div class="del-content">是否删除该农场秀?</div>
          <div class="bottom-btn">
            <div class="cancel" @click="cancelDelShow">否</div>
            <div class="determine" @click="confirmDelShow">是</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 预览 -->
    <div class="farm-preview" v-if="isBoxShowNum === 10">
      <iframe
        class="show-iframe"
        id="show-iframe"
        height="646"
        :src="
          'https://data.darsing.net/show/show.html?manor_show_id=' +
          showId +
          '&templetId=' +
          templetId +
          '&farmId=' +
          farmID +
          '&token=' +
          token
        "
      ></iframe>
    </div>
    <!-- 修改 -->
    <div class="farm-preview farm-edit" v-if="isBoxShowNum === 11">
      <iframe
        class="show-iframe"
        id="show-iframe"
        :src="
          'https://data.darsing.net/show/show.html?title=' +
          farmShow.title +
          '&slogan=' +
          farmShow.slogan +
          '&desc=' +
          farmShow.desc +
          '&templetId=' +
          templetId +
          '&pids=' +
          farmShow.pids +
          '&phone=' +
          farmShow.phone +
          '&farmId=' +
          farmID +
          '&long=' +
          pageFarmInfo[0] +
          '&lat=' +
          pageFarmInfo[1] +
          '&token=' +
          token +
          '&address=' +
          farmInfo.address
        "
      ></iframe>
    </div>
    <!-- 农场秀结束-->
  </div>
</template>

<script>
import {
  deleteBatchApi,
  getBatchInfoApi,
  getPlotListApi,
  postEditBatchApi,
  postEditBatchStateApi,
} from "@/request/api";
import { mapState } from "vuex";
import { editTime } from "@/utils/editTime";
import VueQr from "vue-qr";
export default {
  name: "myphone",
  props: {
    isBoxShowNum: {
      type: Number,
    },

    id: {
      type: Number,
    },
    showId: {
      type: Number,
    },
    sn: {
      type: String,
    },
    position: {
      type: String,
    },
    location: {
      type: String,
    },
    device_id: {
      type: [Number, String],
    },
    templetId: {
      type: [Number, String],
    },
    plot_title: {
      type: String,
    },
    plot_id: {
      type: Number,
    },
    productInfo: {
      type: Object,
    },
    batch_info: {
      type: Object,
    },
  },
  components: {
    VueQr,
  },
  data() {
    return {
      isCalendarShow: false,
      isShowCalendar: false,
      name: "",
      startDateValue: "",
      endDateValue: "",
      batchInfo: {},
      landList: [],
      selectVal: "",
      custom_state: 0,
      dateValue: "",
      startDateValue5: "",
      endDateValue5: "",
      timePageTitle: "",
      timeTipsTxt: "",
      size: 600,
      pageFarmInfo: [],
    };
  },
  watch: {
    isBoxShowNum(newVal) {
      if (newVal === 5) {
        this.timePageTitle = "批次开始时间";
        this.timeTipsTxt = "请选择开始时间";
        this.custom_state = 1;
      } else if (newVal === 6) {
        this.timePageTitle = "批次结束时间";
        this.timeTipsTxt = "请选择结束时间";
        this.custom_state = 2;
      } else if (newVal === 1) {
        this.getBatchInfoApi();
      }
    },
    id() {
      this.getBatchInfoApi();
    },
    farmShow: {
      handler({ title, slogan, desc, pids, phone }) {
        let strPids;
        if (!pids) return;
        if (typeof pids === "string") {
          strPids = JSON.parse(pids).join(",");
        } else {
          strPids = pids.join(",");
        }
        this.$nextTick(() => {
          let long = this.farmInfo.position.split(",")[0];
          let lat = this.farmInfo.position.split(",")[1];
          let ifamSrc = document.getElementById("show-iframe");
          ifamSrc.removeAttribute("src");
          ifamSrc.setAttribute(
            "src",
            `https://data.darsing.net/show/show.html?templetId=${this.templetId}&title=${title}&slogan=${slogan}&desc=${desc}&pids=${strPids}&phone=${phone}&farmId=${this.farmID}&long=${long}&lat=${lat}&token=${this.token}&address=${this.farmInfo.address}`
          );
        });
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
      farmShow: (state) => state.user.farmShow,
      farmInfo: (state) => state.user.farmInfo,
      token: (state) => state.user.token,
    }),
  },
  created() {
    this.pageFarmInfo = this.farmInfo.position.split(",");
    this.getPlotList();
  },
  methods: {
    cancelDelShow() {
      this.$emit("cancelDelShow");
    },
    confirmDelShow() {
      this.$emit("confirmDelShow");
    },
    noDel() {
      this.$emit("noDel");
    },
    async postEditBatchStateApi() {
      let start_time = editTime(this.startDateValue5);
      let end_time = editTime(this.endDateValue5);
      const { code } = await postEditBatchStateApi({
        batch_id: this.id,
        custom_state: this.custom_state,
        start_time,
        end_time,
      });
      if (code === 0) {
        this.$emit("handleOkEdit");
        this.$notify({
          title: "成功",
          message: "已修改",
          type: "success",
        });
      }
    },
    async getPlotList() {
      const { code, results } = await getPlotListApi({
        manor_id: this.farmID,
      });
      if (code === 0) {
        this.landList = results.data;
      }
    },
    async delSn(id) {
      const { code } = await deleteBatchApi({
        batch_ids: [id],
      });
      if (code === 0) {
        this.$emit("delSn");
        this.$notify({
          title: "成功",
          message: "已删除",
          type: "success",
        });
      }
    },
    openCalendar() {
      this.isShowCalendar = true;
    },
    handlePreview() {
      this.$emit("handlePreview");
    },
    handleYesEditTime(index) {
      if (index === 5) {
        this.startDateValue5 = this.dateValue;
        this.custom_state = 1;
      } else {
        this.endDateValue5 = this.dateValue;
        this.custom_state = 2;
      }
      this.postEditBatchStateApi();
    },
    handleNoEditTime(index) {
      if (index === 5) {
        this.startDateValue5 = "";
      } else {
        this.endDateValue5 = "";
      }
      this.$emit("handleNoEditTime");
    },
    async getBatchInfoApi() {
      const { code, results } = await getBatchInfoApi({
        batch_id: this.id,
      });
      if (code === 0) {
        this.batchInfo = results;
        this.startDateValue = results.start_time;
        this.endDateValue = results.end_time;
        this.selectVal = results.plot_title;
      }
    },
    async handleOkEdit() {
      let start_time = editTime(this.startDateValue);
      let end_time = editTime(this.endDateValue);
      const { code } = await postEditBatchApi({
        batch_id: this.id,
        manor_id: this.farmID,
        title: this.batchInfo.title,
        plot_id: this.plot_id,
        custom_state: this.batchInfo.custom_state,
        start_time,
        end_time,
      });
      if (code === 0) {
        this.$emit("handleOkEdit");
        this.$notify({
          title: "成功",
          message: "修改成功",
          type: "success",
        });
      }
    },
    handleNoEdit() {
      this.$emit("handleNoEditTime");
    },
  },
};
</script>

<style lang="less" scoped>
.model {
  width: 331px;
  height: 673px;
  background: url(../assets/image/traceability/phone.png) center center
    no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .hash-calendar.my-calendar {
    width: 296px;
    height: 50%;
    position: absolute;
    bottom: 15px;
    left: 18px;
    top: inherit;
    border-radius: 38px;
    border: 1px solid;

    .calendar_content {
      width: 100%;
      height: 100% !important;
      padding-bottom: 0px;
    }
    .calendar_confirm,
    .calendar_title_date {
      display: none !important;
    }
  }

  .default-box {
    width: 296px;
    height: 642px;
    background: url(../assets/image/traceability/phonebgc.png) center center
      no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 130px;

    img {
      width: 125px;
      height: 125px;
      margin-bottom: 34px;
    }

    .msg {
      font-size: 17px;
      color: #000;
      margin-bottom: 12px;

      span {
        color: #3e90e5;
      }
    }
  }

  .edit-box,
  .info-box {
    width: 296px;
    height: 642px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: relative;
    border-radius: 38px;

    .title {
      width: 100%;
      text-align: center;
      font-size: 18px;
      color: #000000;
      position: absolute;
      z-index: 10;
      top: 29px;
    }

    .edit-main {
      width: 100%;
      flex: 1;
      position: relative;
      background: url(../assets/image/traceability/edit1.png) left top no-repeat
        #f6f6f6;
      background-size: contain;
      border-radius: 38px;
      display: flex;

      .center-card {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 150px;
        background-color: #fff;
        border-radius: 10px;
        width: 274px;
        min-height: 257px;
        display: flex;
        flex-direction: column;
        padding: 27px 16px;

        .input-suffix {
          height: 52px;
          width: 100%;
          border-bottom: 1px solid #efefef;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          color: #000;

          /deep/.el-input {
            width: 150px;

            .el-input__inner {
              border: none;
            }
          }

          /deep/.edit-date-picker {
            width: 133px;

            .el-input__inner {
              width: 100%;
              padding-left: 0px;
            }

            .el-input__prefix {
              left: initial;
              right: 5px;
            }
          }
        }

        /deep/.el-button {
          width: 241px;
          height: 38px;
          border-radius: 19px;
          margin-bottom: 14px;
          margin-left: 0;
          margin-top: 30px;
        }

        /deep/.el-button--info {
          color: #000;
          margin-bottom: 0;
          margin-top: 0;
          background-color: #e0e0e0;
          border-color: #e0e0e0;
        }
      }
    }
  }

  .preview-box,
  .farm-preview {
    width: 296px;
    height: 642px;
    position: relative;
    border-radius: 38px;

    .pre-title {
      width: 100%;
      text-align: center;
      font-size: 18px;
      color: #fff;
      position: absolute;
      z-index: 10;
      top: 29px;
    }

    .pre-bg-img {
      width: 100%;
      height: 100%;
      border-radius: 38px;
    }
  }

  .see-box,
  .farm-share {
    width: 296px;
    height: 642px;
    background: url(../assets/image/traceability/phonebgc2.png) center center
      no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    .qr-code-card {
      width: 225px;
      height: 295px;
      background: #3e90e5;
      border-radius: 5px;
      padding-top: 16px;
      margin-bottom: 43px;

      .qr-title {
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
      }

      .qr-main {
        width: 208px;
        height: 243px;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        .qr-img {
          margin-bottom: 14px;
          width: 152px;
          height: 152px;
        }

        p {
          width: 100%;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          color: #3e90e5;
          line-height: 26px;
        }
      }
    }

    .preview-button {
      width: 103px;
      height: 34px;
      background: #3e90e5;
      border-radius: 5px;
      font-size: 16px;
      font-weight: bold;
      line-height: 34px;
      text-align: center;
      color: #fff;
    }
  }

  .del-box,
  .farm-del {
    width: 296px;
    height: 642px;
    background: url(../assets/image/traceability/phonebgc2.png) center center
      no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 38px;

    .del-message {
      width: 236px;
      height: 161px;
      background: #fff;
      border-radius: 10px;
      display: flex;
      flex-direction: column;

      .del-content {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: #000;
      }

      .bottom-btn {
        width: 100%;
        height: 39px;
        display: flex;
        .cancel {
          width: 50%;
          height: 100%;
          background: #f9f9f9;
          border-bottom-left-radius: 10px;
          line-height: 39px;
          text-align: center;
          color: #666;
          font-size: 14px;
          cursor: pointer;
        }

        .determine {
          width: 50%;
          height: 100%;
          background: #3e90e5;
          border-bottom-right-radius: 10px;
          line-height: 39px;
          text-align: center;
          color: #fff;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
  }

  .set-time-box {
    width: 296px;
    height: 642px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f6f6f6;
    border-radius: 38px;
    padding: 66px 20px 0;

    .set-title {
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      color: #000;
      margin-bottom: 25px;
    }

    .change-time {
      width: 100%;
      margin-bottom: 58px;
      height: 49px;
      background: rgba(62, 144, 229, 0.11);
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 0 20px 0 14px;
      position: relative;

      .icon2 {
        width: 20px;
        height: 20px;
        margin-right: 17px;
      }

      span {
        font-size: 14px;
        color: #000;
        flex: 1;
      }

      .icon3 {
        width: 6px;
        height: 10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        z-index: 20;
      }
      /deep/.my-date-picker {
        width: 100%;
        height: 100%;
        border: none;
        background-color: initial;

        .el-input__inner {
          width: 100%;
          height: 100%;
          padding: 0 0 0 36px;
          border: none;
          background-color: initial;

          &::-webkit-input-placeholder {
            color: #000;
          }
        }

        .el-input__prefix {
          left: 5px;
          right: initial;
          color: #3e90e5;
          font-size: 18px;
        }
      }
    }

    /deep/.el-button {
      width: 100%;
      height: 38px;
      border-radius: 19px;
      margin-bottom: 14px;
      margin-left: 0;
    }

    /deep/.el-button--info {
      color: #000;
      margin-bottom: 0;
      background-color: #e0e0e0;
      border-color: #e0e0e0;
    }

    .icon5 {
      width: 278px;
      height: auto;
      position: absolute;
      bottom: 46px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .info-box {
    .info-bg-img {
      width: 299px;
      height: auto;
      position: absolute;
      left: -1px;
      top: -2px;
      border-radius: 38px;
    }

    .info-card {
      position: absolute;
      width: 100%;
      left: 0;
      top: 278px;
      height: 349px;
      background: #fff;
      border-radius: 20px;
      padding: 23px 10px;

      .info-title {
        width: 100%;
        padding: 0 11px;
        font-size: 18px;
        color: #0e2f53;
        margin-bottom: 19px;
        font-weight: bold;
      }

      .info-col {
        width: 100%;
        height: 31px;
        background: #f5f7fc;
        border-radius: 3px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        padding: 0 11px;

        .info-col-title {
          color: #000;
          font-size: 14px;
          font-weight: bold;
        }

        .info-col-content {
          font-size: 12px;
          color: #666;
        }

        &.content-col {
          height: 150px;
          flex-direction: column;
          padding: 12px 16px;
          align-items: flex-start;

          .info-col-title {
            margin-bottom: 12px;
          }

          .info-col-content {
            flex: 1;
            width: 100%;
            line-height: 21px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
  .see-box {
    #qrCode {
      width: 152px;
      height: 152px;
      img,
      canvas {
        width: 100% !important;
        height: 100% !important;
        img {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }
  .farm-share {
    background: url("../assets/image/traceability/phonebgc.png") center center
      no-repeat;
    .qr-main {
      p {
        font-weight: 400;
        &:last-of-type {
          font-size: 14px;
          color: #000;
        }
      }
    }
  }
  .farm-del {
    background: url(../assets/image/show/preview1.png) center center no-repeat;
    .layer {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 38px;
      .cancel {
        color: #666;
      }
      .determine {
        border-bottom-right-radius: 9px;
        background-color: #607e5b !important;
      }
    }
  }
  .farm-preview {
    overflow-y: hidden;
    &::-webkit-scrollbar {
      width: 0;
    }
    .show-iframe {
      width: 100%;
      height: 100%;
      border-radius: 38px;
    }
  }
  .farm-edit {
    .bg-img {
      height: auto !important;
    }
    .img-box {
      margin: 0 auto;
      margin-bottom: 47px;
      width: 258px;
      height: 218px;
      border: 1px dashed #4f534e;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .img {
        width: 28px;
        height: 28px;
        margin-bottom: 17px;
      }
      .list-img {
        width: 100%;
        height: 100%;
      }
      span {
        font-size: 12px;
        color: #999;
      }
    }
  }
}
</style>
